.textbox {
    width: 100%;
    box-sizing: border-box;
}

.boxcolor {
    box-sizing: border-box;
    border-radius: 4px;
    background: #ededed;
    border: 0;
    min-height: 2em;
}

.radiobox{
    width: 100%;
    box-sizing: border-box;
    display: flex;
}

.radiopad{
    padding-right: 10px;
    padding-left: 2px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
}