.colors {
    font-family: Helvetica;
    color: #3f3f3f;
    background-color: #fafafa;
    padding: 10px
    
}

.border {
    border-style: solid;
    border-width: 0px
}

.outline {
    width: 100%;
    border-radius:40px
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
}

@media only screen and (min-width: 15cm) {
    .outline {
        max-width: 20cm;
        padding-top: 10px;
    }
}

.column {
    display: flex;
    flex-direction: column;
}

.nextstepbutton {
    border-radius: 4px;
    padding: 8px;
    border: 0;
    background-color: #dfdfdf;
    color: #3f3f3f;
    font-size: 1em;
    width: 100%;
    margin-bottom:8px;
    box-sizing: border-box;

}

.learnmorebutton {
    border-radius: 4px;
    padding: 8px;
    border: 0;
    background-color: #dfdfdf;
    color: #3f3f3f;
    font-size: 1em;
    width: 100%
}

.stepbutton {
    border-radius: 4px;
    padding: 8px;
    border: 0;
    background-color: #dfdfdf;
    color: #3f3f3f;
    font-size: 1em;
    width: auto;
    box-sizing: border-box;
}

.title {
    font-size: 2em;
    padding-bottom: 10px
}


.titlesmall {
    font-size: 1.25em;
    padding-bottom: 10px
}

.subtext {
    padding-bottom: 10px;
}

.halvedtextbox {
    width: 100%;
    padding: 8px;
    border: 0;
    font-size: 1em;
}

.boxcolor {
    box-sizing: border-box;
    border-radius: 4px;
    background: #ededed;
    border: 0;
}

.segement {
    min-width: 33%;
    padding: 8px;
}

.wrap {
    flex-wrap: wrap;
    flex-direction: row;
}