.form-light {
    font-family: Helvetica;
    color: #3f3f3f;
    background-color: #fafafa;
    padding: 10px;
}

.border {
    border-style: solid;
    border-width: 0px
}

.center1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.round {
    border-radius: 40px 40px;
    border: 0;
}

.buttonbar {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap-reverse;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    box-sizing: border-box
}

.buttonframe {
    width: 50%;
    padding: 2px;
    box-sizing: border-box
}


.buttons {
    border-radius: 4px;
    padding: 8px;
    border: 0;
    font-size: 1em;
    width: 100%;
    background-color: #dfdfdf;
    color: #3f3f3f;
}


.disabledbutton {
    background-color: #f1f1f1;
    color: #b6b6b6;
}

.submit {
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
    box-sizing: border-box;
    padding-bottom: 10px;
}

@media only screen and (max-width: 15cm) {
    .buttonframe {
        padding-top: 10px;
        width: 100%
    }
}



[class*="sizing-"] {
    flex-shrink: 1;
    flex-grow: 1;
}


@media only screen and (min-width: 15cm) {
    .sizing-tablet {
        max-width:20cm
    }
}
