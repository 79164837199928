.home {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    overflow-y: scroll;
}

.homecentered {
    top: 0;
    left: 0;
    position: fixed;
}


.startpage {
    justify-content: center;
    align-items: center;
}

.questionpage {
    justify-content: center;
    align-items:flex-start;
    padding-top: 50px;
}