.question {
    padding: 2px;
    border-style: solid;
    border-width: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
}

@media only screen and (max-width: 15cm) {
    .question{
        width: 100%;
    }
}

.question-query {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items:end;
    padding: 2px;
    min-width: 4em;
    box-sizing: border-box;
}

.question-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items:center;
    padding: 2px;
    min-width: 4em;
    box-sizing: border-box;
}

.question-title {
    font-size:1em;
    padding-right: 0.5em;
}

.question-subtitle {
    font-size:0.75em
}

.question-error {
    padding-top: 2px;
    padding-left: 2px;
    font-size: 0.75em;
    box-sizing: border-box;
    width: 100%;
    color: #ef3030
}