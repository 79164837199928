.elementlayout {
    display: flex;
    flex-direction: column;
    border-style: solid;
    width: 100%;
    border-width: 0px;
    box-sizing: border-box;
    padding-top: 4px;
    border: 0;
}

.form-header-title {
    font-size: 2em;
}

.form-header {
    box-sizing: border-box;
    padding: 2px;
    padding-top: 10px;
}

.form-header-title-0 {
    font-size: 2em;
}

.form-header-title-1 {
    font-size: 1.5em;
}

.form-header-title-2 {
    font-size: 1.25em;
}

.form-header-title-3 {
    font-size: 1.1em;
}
@media only screen and (min-width: 15cm) {
    .halflayout {
        flex-wrap: wrap;
        flex-direction: row;
    }
}

.disabled {
    font-family: Helvetica;
    color: #a7a7a7;
}